import { SystemValueInfoWithValue } from './../components/dsrConfirmActionPopup/dsrConfirmActionPopup'
import {
  DsrDefaultTemplateSetting,
  DsrEmailTemplateTypes
} from './../dsrEmailTemplates/dsrEmailTemplatesSlice'
import {
  mapQueryAllDsrRequestsRequiredDetails,
  mapQueryDSRAssociatedForms,
  mapQueryDSRAttributesValidated,
  mapQueryDSRReportById,
  mapQueryDSRReportByIds,
  mapQueryDSRRequestById,
  mapQueryDsrRequestIdsForBulkAction,
  mapQueryDSRRequestsSummaryByStatus,
  mapQueryFilters,
  mapQueryReportAttributesSummary,
  mapQueryRequestList,
  mapQueryRequestsSummaryByType,
  mapQueryTotalRequests,
  mutationBulkShareReports,
  queryAllDsrRequestsRequiredDetails,
  queryCloseDsrRequest,
  queryDSRAssociatedForms,
  queryDSRAttributesValidated,
  queryDSRReportById,
  queryDsrReportByIds,
  queryDSRRequestById,
  queryDSRRequestIdsForBulkActions,
  queryDSRRequestList,
  queryDSRRequestsListForBulkAction,
  queryDSRRequestsSummaryByStatus,
  queryExcludeAttributeForDsrReport,
  queryExtendDsrRequest,
  queryReassignDsrRequest,
  queryRejectDsrRequest,
  queryReportAttributesSummary,
  queryRequestsSummaryByType,
  queryTotalRequests,
  queryVerifyDsrRequest
} from './queries'
import {
  PAGE,
  DSR_REQUEST_TYPES,
  DSR_REQUEST_STEP,
  FILTER_DSR_REQUEST_ASSIGNEE,
  FILTER_STATUS_KEY,
  FILTER_DSR_REQUEST_STEP,
  FILTER_DSR_REQUEST_TEMPLATE,
  FILTER_DSR_REQUEST_TYPE,
  DSR_REQUEST_STATUS_FILTER_TYPES,
  DSR_FILTER_DUE_DATE,
  DSR_FILTER_CREATED_DATE,
  PAGE_SIZE
} from '../../../constants'
import { FilterParams } from '../../../interfaces'
import graphqlService from '../../../services/graphqlService'
import { DsrRequestStatus, DsrRequestType, UserEntity } from '../../../services/graphqlSchemaTypes'
import { defaultSortParams, getSortDirection, SortParams } from '../../../utils/sortUtil'
import apiService from '../../../services/api/apiService'
import { DsrFormJsonParsed } from '../dsrForms/dsrFormsSlice'
import { NameIdSummary } from '../../pia/piaSlice'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

export interface RectificationData {
  attributeId: string
  attributeName?: string
  oldValue: string
  newValue: string
}

export type DsrRequestAssignee = {
  name: string
  email: string
}

export type DsrDataSubjectMandatoryDetails = {
  firstName: string
  lastName: string
  email: string
}

export type DsrDataSubjectDetails = {
  attributeName?: string
  attributeValue: string
  formFieldId?: string
}
export type DsrRequestAttachment = { fileName: string; fileContent: string; id: string }

export type DsrRequest = {
  id?: string
  name?: string
  email?: string
  formId?: string
  formName?: string
  formRawJSON?: string
  formJsonParsed?: DsrFormJsonParsed
  createdAt?: string
  approvedBy?: string
  approvedAt?: string
  updateTimestamp?: string
  dueDate?: string
  rectificationData?: RectificationData[]
  erasureData?: string
  status?: DsrRequestStatus
  requestType?: DsrRequestType
  emailVerificationSentDate?: string
  emailVerificationCompletionDate?: string
  detectedEntities?: UserEntity[]
  assignee?: DsrRequestAssignee[]
  attachments?: DsrRequestAttachment[]
  dataSubjectDetails?: DsrDataSubjectDetails[]
  dataSubjectMandatoryDetails?: DsrDataSubjectMandatoryDetails
  detectedEntity?: {
    id: string
    name: string[]
    datasourceIds: string[]
  }
  entityId?: string
  detectedEntityId?: string
  submittedValues?: string
  rectificationAttributeIds?: string
  workflowConfig?: {
    id: string
    name: string
    requestType: DsrRequestType
  }
  manualAction?: '' | DsrManualAction
  automationFailureOccurred?: boolean
}

export enum DsrManualAction {
  APPROVE = 'APPROVE',
  DISCOVER_DATA_AND_VALIDATE = 'DISCOVER_DATA_AND_VALIDATE',
  SHARE_REPORT = 'SHARE_REPORT',
  CLOSE_REQUEST = 'CLOSE_REQUEST'
}

export interface AttributeValidated {
  name: string
  id: string
  attributeId: string
  action: string
  reason: string[]
  remark: string
  dataSources: DataSource[]
  identifier: string
  includeInReport: boolean
}

type DataSource = {
  id: string
  name: number
}

export interface AttributesValidated {
  list: AttributeValidated[]
  reportId: string
}

export type DsrBulkShareReportData = {
  subject?: string
  compiledHTMLTemplate?: string | null
  defaultSettings?: DsrDefaultTemplateSetting[]
  saveSettings?: boolean
  mainEmailContent?: string
  footerEmailContent?: string
  includedSysValues?: string[]
  isSubjectTouched?: boolean
  systemValuesWithData?: SystemValueInfoWithValue[]
  associatedTemplateId?: string
  emailTemplateOptions?: { key: string; value: string; text: string }[]
}

export enum BULK_SHARE_ACTION_TYPES {
  selectedReports = 'BULK_SHARE_REPORT',
  allReports = 'BULK_SHARE_ALL_REPORTS'
}

interface DSRRequestState {
  list?: DsrRequest[]
  request?: DsrRequest
  sort: SortParams
  isRequestClosed?: boolean
  isRequestVerified?: boolean
  isRequestRejected?: boolean
  isRequestExtended?: boolean
  filterOptions: FilterOptions
  totalRequests?: number
  totalRequestsToAccess?: number
  totalRequestsToErase?: number
  totalRequestsToNotSell?: number
  totalRequestsOther?: number
  totalRequestsInProgress?: number
  totalRequestsClosed?: number
  totalRequestsInUnverified?: number
  attributesValidated?: AttributesValidated
  totalAttributesValidated?: number
  totalAttributesDetected?: number
  totalAttributesRectified?: number
  totalAttributesErased?: number
  report?: DsrReportPrint
  reportId?: string
  isAttributeExcludedForReport?: boolean
  assigneeEmail?: Array<string>
  refreshAttributesValidated?: boolean
  requestsReportsReady?: string[]
  dsrBulkShareReports: {
    [key in DsrEmailTemplateTypes]?: DsrBulkShareReportData
  }
  listForBulkAction?: DsrRequest[]
  dsrRequestIdsForBulkAction?: string[]
  allDsrRequestRequiredDetails?: { id: string; hasOpenTickets: boolean; status: DsrRequestStatus }[]
  dsrAssociatedForms?: NameIdSummary[]
}

export type FilterOptions = {
  [filterKey: string]: string[]
}

export interface DSRRequest extends DsrRequest {
  hasOpenTickets?: boolean
}

export type DsrRequestFilterParams = {
  filter?: {
    key: string
    values: string[]
  }[]
  booleanFilter?: {
    key: string
    value: boolean
  }[]
  dateFilter?: {
    key?: string
    start?: number
    end?: number
  }[]
  sortBy?: string
  datasourceIds?: string[]
}

export type IGetRequestListParams = {
  id?: string
  [FILTER_STATUS_KEY]?: string
  [FILTER_DSR_REQUEST_STEP]?: string[]
  [FILTER_DSR_REQUEST_ASSIGNEE]?: boolean
  [FILTER_DSR_REQUEST_TYPE]?: string
  [FILTER_DSR_REQUEST_TEMPLATE]?: string
  filters?: DsrRequestFilterParams & { searchQuery?: string }
  [PAGE]: number
  [PAGE_SIZE]?: number
}

export const initialState: DSRRequestState = {
  sort: defaultSortParams,
  filterOptions: {
    [FILTER_STATUS_KEY]: [],
    [FILTER_DSR_REQUEST_ASSIGNEE]: [],
    [FILTER_DSR_REQUEST_TYPE]: [],
    [FILTER_DSR_REQUEST_TEMPLATE]: []
  },
  refreshAttributesValidated: false,
  dsrBulkShareReports: {}
}

const filterResponseRaw = {
  dsrListFilters: {
    [FILTER_DSR_REQUEST_ASSIGNEE]: {
      edges: [
        {
          node: {
            [FILTER_DSR_REQUEST_ASSIGNEE]: 'adityasingh@gmail.com'
          }
        }
      ]
    },
    [FILTER_DSR_REQUEST_TEMPLATE]: {
      edges: [
        {
          node: {
            [FILTER_DSR_REQUEST_TEMPLATE]: 'Gdpr_Wf1'
          }
        }
      ]
    }
  }
}

export const FETCH_DSR_REQUEST_LIST = 'fetch/dsr/request/list'
export const fetchDSRRequests = createAsyncThunk(
  FETCH_DSR_REQUEST_LIST,
  async (params: IGetRequestListParams) => {
    const resultRaw = await graphqlService.execute(queryDSRRequestList(params))
    return mapQueryRequestList(resultRaw)
  }
)

export const FETCH_DSR_REQUEST_LIST_FOR_BULK_ACTION = 'fetch/dsr/request/list/bulk/action'
export const fetchDSRRequestListForBulkAction = createAsyncThunk(
  FETCH_DSR_REQUEST_LIST_FOR_BULK_ACTION,
  async (requestIds: string[]) => {
    const resultRaw = await graphqlService.execute(queryDSRRequestsListForBulkAction(requestIds))
    return mapQueryRequestList(resultRaw)
  }
)

export const FETCH_DSR_REQUEST_IDS_FOR_BULK_ACTION = 'fetch/dsr/request/ids/bulk/action'
export const fetchDSRRequestsIdsForBulkAction = createAsyncThunk(
  FETCH_DSR_REQUEST_IDS_FOR_BULK_ACTION,
  async (params: DsrRequestFilterParams & { searchQuery?: string }) => {
    const resultRaw = await graphqlService.execute(queryDSRRequestIdsForBulkActions(params))
    const mappedData = mapQueryDsrRequestIdsForBulkAction(resultRaw)
    return {
      requestIds: mappedData,
      status: 'SUCCESS'
    }
  }
)

export const FETCH_ALL_DSR_REQUESTS_REQUIRED_DETAILS = 'fetch/all/dsr/requests/required/details'
export const fetchAllDsrRequestsRequiredDetails = createAsyncThunk(
  FETCH_ALL_DSR_REQUESTS_REQUIRED_DETAILS,
  async () => {
    const resultRaw = await graphqlService.execute(queryAllDsrRequestsRequiredDetails())
    return mapQueryAllDsrRequestsRequiredDetails(resultRaw)
  }
)

export const mapDsrStatusToApi = {
  [DSR_REQUEST_STATUS_FILTER_TYPES.inProgress]: 'idVerified,dataDiscovery,reportGeneration',
  [DSR_REQUEST_STATUS_FILTER_TYPES.closed]: 'closed,rejected',
  [DSR_REQUEST_STATUS_FILTER_TYPES.unverified]: 'idUnverified'
}
export const mapDsrDueDateToApi = {
  [DSR_FILTER_DUE_DATE.PAST_DUE]: {
    start: dayjs('2010-01-01').unix(), // start of 2010
    end: dayjs().subtract(1, 'day').endOf('day').unix() // end of previous day
  },
  [DSR_FILTER_DUE_DATE.WITHIN_ONE_WEEK]: {
    start: dayjs().startOf('day').unix(), // start of current day
    end: dayjs().add(7, 'day').endOf('day').unix() // in next 7 days
  },
  [DSR_FILTER_DUE_DATE.WITHIN_ONE_MONTH]: {
    start: dayjs().startOf('day').unix(), // start of current day
    end: dayjs().add(30, 'day').endOf('day').unix() // in next 30 days
  }
}

export const mapDsrCreatedDateToApi = {
  [DSR_FILTER_CREATED_DATE.WITHIN_ONE_WEEK]: {
    start: dayjs().subtract(7, 'day').startOf('day').unix(), // in last 7 days
    end: dayjs().endOf('day').unix() // end of current day
  },
  [DSR_FILTER_CREATED_DATE.WITHIN_ONE_MONTH]: {
    start: dayjs().subtract(30, 'day').startOf('day').unix(), // in last 30 days
    end: dayjs().endOf('day').unix() // end of current day
  }
}

export type DsrBulkShareReportParams = {
  loggendInUserEmail?: string
  requestIds?: string[]
  timezone?: string
  closeRequests?: boolean
  sendCloseEmail?: boolean
  dsrReportShareData?: {
    [key in DsrEmailTemplateTypes]?: DsrBulkShareReportData
  }
  bulkShareFilters: DsrRequestFilterParams & { searchQuery?: string }
  bulkShareActionType: BULK_SHARE_ACTION_TYPES
}

export const FETCH_DSR_REQUEST_BY_STATUS = 'fetch/dsr/requests/by-status'
export const fetchDSRRequestsSummaryByStatus = createAsyncThunk(
  FETCH_DSR_REQUEST_BY_STATUS,
  async (params: IGetRequestListParams) => {
    const resultRaw = await graphqlService.execute(queryDSRRequestsSummaryByStatus(params))
    return mapQueryDSRRequestsSummaryByStatus(resultRaw)
  }
)

export const FETCH_DSR_REQUEST_BY_ID = 'fetch/dsr/request/by-id'
export const fetchDSRRequestById = createAsyncThunk(FETCH_DSR_REQUEST_BY_ID, async (id: string) => {
  const resultRaw = await graphqlService.execute(queryDSRRequestById(id))
  return mapQueryDSRRequestById(resultRaw)
})

export const FETCH_DSR_REPORT_BY_IDS = 'fetch/dsr/report/by-ids'
export const fetchDSRReportByIds = createAsyncThunk(
  FETCH_DSR_REPORT_BY_IDS,
  async (ids: string[]) => {
    const resultRaw = await graphqlService.execute(queryDsrReportByIds(ids))
    return mapQueryDSRReportByIds(resultRaw)
  }
)

export const ACTION_DSR_BULK_SHARE_REPORT = 'dsr/bulk/share/report'
export const dsrBulkShareReportmutation = createAsyncThunk(
  ACTION_DSR_BULK_SHARE_REPORT,
  async (params: DsrBulkShareReportParams) => {
    await graphqlService.execute(mutationBulkShareReports(params))
  }
)
export interface DsrReportValidatedAttribute {
  name: string
  value?: string
  previousValue?: string
  action: string
  reason: string[]
  remarks: string
  includeInReport: boolean
}
export interface DsrReportPrint {
  requestType?: DsrRequestType
  formJsonParsed?: DsrFormJsonParsed
  requestName?: string
  dataSubjectDetails?: any
  attributes: DsrReportValidatedAttribute[]
}

export const FETCH_DSR_ASSIGNEE_EMAILS = 'fetch/dsr/request/assignee-emails'
export const fetchDSRAssigneeEmails = createAsyncThunk(FETCH_DSR_ASSIGNEE_EMAILS, async () => {
  return await apiService.getDSRAssigneeEmails()
})

export const FETCH_DSR_ASSOCIATED_FORMS = 'fetch/dsr/associated/forms'
export const fetchDSRAssociatedForms = createAsyncThunk(FETCH_DSR_ASSOCIATED_FORMS, async () => {
  const resultRaw = await graphqlService.execute(queryDSRAssociatedForms())
  return mapQueryDSRAssociatedForms(resultRaw)
})

export const FETCH_DSR_REPORT_BY_ID = 'fetch/dsr/report/by-id'
export const fetchDSRReportById = createAsyncThunk(FETCH_DSR_REPORT_BY_ID, async (id: string) => {
  const resultRaw = await graphqlService.execute(queryDSRReportById(id))
  return mapQueryDSRReportById(resultRaw)
})

export const FETCH_REQUEST_SUMMARY_BY_TYPE = 'fetch/request/summary/by-type'
export const fetchRequestsSummaryByType = createAsyncThunk(
  FETCH_REQUEST_SUMMARY_BY_TYPE,
  async () => {
    const resultRaw = await graphqlService.execute(queryRequestsSummaryByType())
    return mapQueryRequestsSummaryByType(resultRaw)
  }
)

export const FETCH_REQUESTS_TOTAL = 'fetch/requests/total'
export const fetchTotalRequests = createAsyncThunk(FETCH_REQUESTS_TOTAL, async () => {
  const resultRaw = await graphqlService.execute(queryTotalRequests())
  return mapQueryTotalRequests(resultRaw)
})

export interface ReportAttributesSummaryParams {
  requestId: string
  entityId?: string
  datasourceId?: string
  filters: FilterParams
}
export const FETCH_DSR_REPORTS_ATTS_SUMMARY = 'fetch/dsr/attrs/summary'
export const fetchReportAttributesSummary = createAsyncThunk(
  FETCH_DSR_REPORTS_ATTS_SUMMARY,
  async (params: ReportAttributesSummaryParams) => {
    const resultRaw = await graphqlService.execute(queryReportAttributesSummary(params))
    return mapQueryReportAttributesSummary(resultRaw)
  }
)

export interface FetchAttributesValidatedParams {
  requestType: DsrRequestType
  requestId: string
}
export const FETCH_DSR_ATTEIBUTES_VALIDATED = 'fetch/dsr/attributes/validated'
export const fetchDSRAttributesValidated = createAsyncThunk(
  FETCH_DSR_ATTEIBUTES_VALIDATED,
  async (params: FetchAttributesValidatedParams) => {
    const resultRaw = await graphqlService.execute(queryDSRAttributesValidated(params))
    return mapQueryDSRAttributesValidated(resultRaw, params.requestType)
  }
)

export interface IncludeAttributeInReportParams {
  attributeIds: string[]
  dsrReportId: string
}
export const UPDATE_EXCLUDE_FROM_REPORT = 'update/exclude-from-report'
export const excludeAttributeForDsrReport = createAsyncThunk(
  UPDATE_EXCLUDE_FROM_REPORT,
  async (params: IncludeAttributeInReportParams) => {
    await graphqlService.execute(queryExcludeAttributeForDsrReport(params))
  }
)

export const DSR_REQUEST_CLOSE = 'dsr/request/close'
export const closeDsrRequest = createAsyncThunk(
  DSR_REQUEST_CLOSE,
  async (params: DsrRequestActionParams) => {
    await graphqlService.execute(queryCloseDsrRequest(params))
  }
)

export interface DsrRequestActionParams {
  requestIds: string[]
  dueDate?: string
  content?: string
  subject?: string
  systemValues?: string[]
}
export const DSR_REQUEST_REJECT = 'dsr/request/reject'
export const rejectDsrRequest = createAsyncThunk(
  DSR_REQUEST_REJECT,
  async (params: DsrRequestActionParams) => {
    await graphqlService.execute(queryRejectDsrRequest(params))
  }
)

export const DSR_REQUEST_EXTEND = 'dsr/request/extend'
export const extendDsrRequest = createAsyncThunk(
  DSR_REQUEST_EXTEND,
  async (params: DsrRequestActionParams) => {
    await graphqlService.execute(queryExtendDsrRequest(params))
  }
)

export const DSR_REQUEST_APPROVE = 'dsr/request/approve'
export const approveDsrRequest = createAsyncThunk(
  DSR_REQUEST_APPROVE,
  async (params: DsrRequestActionParams, { dispatch }) => {
    await graphqlService.execute(queryVerifyDsrRequest(params))
    /**Fetch id status after approval */
    if (params.requestIds.length === 1) {
      dispatch(fetchDSRRequestById(params.requestIds[0]))
    }
  }
)

export interface DsrReassignRequestParams {
  requestIds: string[]
  assigneeEmailId: string
  assigneeName?: string
  assignorEmailId: string
  emailBody: string
}
export const DSR_REQUEST_REASSIGN = 'dsr/request/reassign'
export const reassignDsrRequest = createAsyncThunk(
  DSR_REQUEST_REASSIGN,
  async (params: DsrReassignRequestParams) => {
    await graphqlService.execute(queryReassignDsrRequest(params))
  }
)

export const fetchFilters = createAsyncThunk(
  'fetch/dsr/data-discovery/dataSources/filters',
  async () => {
    return {
      ...mapQueryFilters(filterResponseRaw),
      [FILTER_STATUS_KEY]: Object.values(DSR_REQUEST_STEP),
      [FILTER_DSR_REQUEST_TYPE]: Object.values(DSR_REQUEST_TYPES)
    }
  }
)

const requestListSlice = createSlice({
  name: 'dsrRequestList',
  initialState,
  reducers: {
    setSort: (state, { payload }) => {
      state.sort = getSortDirection(state.sort, payload.sortParams)
    },
    resetTotalRequests: (state) => {
      state.totalRequests = initialState.totalRequests
    },
    resetIsRequestClosed: (state) => {
      state.isRequestClosed = initialState.isRequestClosed
    },
    resetIsRequestRejected: (state) => {
      state.isRequestRejected = initialState.isRequestRejected
    },
    resetIsRequestExtended: (state) => {
      state.isRequestExtended = initialState.isRequestExtended
    },
    resetIsRequestVerified: (state) => {
      state.isRequestVerified = initialState.isRequestVerified
    },
    resetRequestDetails: (state) => {
      state.request = initialState.request
    },
    resetDSRAttributesValidated: (state) => {
      state.attributesValidated = initialState.attributesValidated
    },
    resetExcludeAttributesForReport: (state) => {
      state.isAttributeExcludedForReport = initialState.isAttributeExcludedForReport
    },
    resetDSRRequestsSummaryByStatus: (state) => {
      state.totalRequests = initialState.totalRequests
      state.totalRequestsInUnverified = initialState.totalRequestsInUnverified
      state.totalRequestsClosed = initialState.totalRequestsClosed
      state.totalRequestsInProgress = initialState.totalRequestsInProgress
    },
    resetTotalAttributesDetected: (state) => {
      state.totalAttributesDetected = 0
    },
    resetDSRRequests: (state) => {
      state.list = initialState.list
    },
    resetRefreshAttributesValidated: (state) => {
      state.refreshAttributesValidated = initialState.refreshAttributesValidated
    },
    resetRequestsReportsReady: (state) => {
      state.requestsReportsReady = initialState.requestsReportsReady
    },
    resetDsrBulkShareReportsData: (state) => {
      state.dsrBulkShareReports = initialState.dsrBulkShareReports
    },
    setDsrBulkShareReportsData: (state, { payload }) => {
      state.dsrBulkShareReports = {
        ...state.dsrBulkShareReports,
        [payload.eventType]: { ...state.dsrBulkShareReports[payload.eventType], ...payload.data }
      }
    },
    setDefaultSettings: (state, { payload }) => {
      state.dsrBulkShareReports[payload.eventType] = {
        ...state.dsrBulkShareReports[payload.eventType],
        defaultSettings: payload.data
      }
    },
    setSaveSettings: (state, { payload }) => {
      state.dsrBulkShareReports[payload.eventType] = {
        ...state.dsrBulkShareReports[payload.eventType],
        saveSettings: payload.data
      }
    },
    setMainEmailContent: (state, { payload }) => {
      state.dsrBulkShareReports[payload.eventType] = {
        ...state.dsrBulkShareReports[payload.eventType],
        mainEmailContent: payload.data
      }
    },
    setFooterEmailContent: (state, { payload }) => {
      state.dsrBulkShareReports[payload.eventType] = {
        ...state.dsrBulkShareReports[payload.eventType],
        footerEmailContent: payload.data
      }
    },
    setIncludedSysValues: (state, { payload }) => {
      state.dsrBulkShareReports[payload.eventType] = {
        ...state.dsrBulkShareReports[payload.eventType],
        includedSysValues: payload.data
      }
    },
    setIsSubjectTouched: (state, { payload }) => {
      state.dsrBulkShareReports[payload.eventType] = {
        ...state.dsrBulkShareReports[payload.eventType],
        isSubjectTouched: payload.data
      }
    },
    setSystemValuesWithData: (state, { payload }) => {
      state.dsrBulkShareReports[payload.eventType] = {
        ...state.dsrBulkShareReports[payload.eventType],
        systemValuesWithData: payload.data
      }
    },
    setSubject: (state, { payload }) => {
      state.dsrBulkShareReports[payload.eventType] = {
        ...state.dsrBulkShareReports[payload.eventType],
        subject: payload.data
      }
    },
    setCompiledHtmlTemplate: (state, { payload }) => {
      state.dsrBulkShareReports[payload.eventType] = {
        ...state.dsrBulkShareReports[payload.eventType],
        compiledHTMLTemplate: payload.data
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDSRRequests.fulfilled, (state, { payload }) => {
      state.list = payload
    })
    builder.addCase(fetchDSRRequestsSummaryByStatus.fulfilled, (state, { payload }) => {
      state.totalRequestsInProgress = payload.totalRequestsInProgress
      state.totalRequestsInUnverified = payload.totalRequestsInUnverified
      state.totalRequests = payload.totalRequests
      state.totalRequestsClosed = payload.totalRequestsClosed
    })
    builder.addCase(fetchDSRRequestById.fulfilled, (state, { payload }) => {
      state.request = payload
    })
    builder.addCase(fetchRequestsSummaryByType.fulfilled, (state, { payload }) => {
      const {
        totalRequestsToAccess,
        totalRequestsToNotSell,
        totalRequestsToErase,
        totalRequestsOther
      } = payload
      state.totalRequestsToAccess = totalRequestsToAccess
      state.totalRequestsToNotSell = totalRequestsToNotSell
      state.totalRequestsToErase = totalRequestsToErase
      state.totalRequestsOther = totalRequestsOther
    })
    builder.addCase(fetchTotalRequests.fulfilled, (state, { payload }) => {
      state.totalRequests = payload
    })
    builder.addCase(fetchFilters.fulfilled, (state, { payload }) => {
      state.filterOptions = payload
    })
    builder.addCase(closeDsrRequest.fulfilled, (state) => {
      state.isRequestClosed = true
    })
    builder.addCase(closeDsrRequest.rejected, (state) => {
      state.isRequestClosed = false
    })
    builder.addCase(rejectDsrRequest.fulfilled, (state) => {
      state.isRequestRejected = true
    })
    builder.addCase(rejectDsrRequest.rejected, (state) => {
      state.isRequestRejected = false
    })
    builder.addCase(extendDsrRequest.fulfilled, (state) => {
      state.isRequestExtended = true
    })
    builder.addCase(extendDsrRequest.rejected, (state) => {
      state.isRequestExtended = false
    })
    builder.addCase(approveDsrRequest.fulfilled, (state) => {
      state.isRequestVerified = true
    })
    builder.addCase(approveDsrRequest.rejected, (state) => {
      state.isRequestVerified = false
    })
    builder.addCase(fetchDSRAttributesValidated.fulfilled, (state, { payload }) => {
      state.attributesValidated = payload
    })
    builder.addCase(fetchReportAttributesSummary.fulfilled, (state, { payload }) => {
      state.totalAttributesValidated = payload.totalAttributesValidated
      state.totalAttributesDetected = payload.totalAttributesDetected
      state.totalAttributesRectified = payload.totalAttributesRectified
      state.totalAttributesErased = payload.totalAttributesErased
    })
    builder.addCase(fetchDSRReportById.fulfilled, (state, { payload }) => {
      state.report = payload
    })
    builder.addCase(excludeAttributeForDsrReport.fulfilled, (state) => {
      state.isAttributeExcludedForReport = true
      state.refreshAttributesValidated = true
    })
    builder.addCase(excludeAttributeForDsrReport.rejected, (state) => {
      state.isAttributeExcludedForReport = false
    })
    builder.addCase(fetchDSRAssigneeEmails.fulfilled, (state, { payload }) => {
      state.assigneeEmail = payload.assigneeEmail
    })
    builder.addCase(fetchDSRReportByIds.fulfilled, (state, { payload }) => {
      state.requestsReportsReady = payload
    })
    builder.addCase(fetchDSRRequestListForBulkAction.fulfilled, (state, { payload }) => {
      state.listForBulkAction = payload
    })
    builder.addCase(fetchDSRRequestsIdsForBulkAction.fulfilled, (state, { payload }) => {
      state.dsrRequestIdsForBulkAction = payload.requestIds
    })
    builder.addCase(fetchAllDsrRequestsRequiredDetails.fulfilled, (state, { payload }) => {
      state.allDsrRequestRequiredDetails = payload
    })
    builder.addCase(fetchDSRAssociatedForms.fulfilled, (state, { payload }) => {
      state.dsrAssociatedForms = payload
    })
  }
})

export const {
  setSort,
  resetIsRequestClosed,
  resetIsRequestRejected,
  resetIsRequestExtended,
  resetRequestDetails,
  resetIsRequestVerified,
  resetDSRAttributesValidated,
  resetTotalAttributesDetected,
  resetExcludeAttributesForReport,
  resetDSRRequests,
  resetTotalRequests,
  resetDSRRequestsSummaryByStatus,
  resetRefreshAttributesValidated,
  resetRequestsReportsReady,
  setDsrBulkShareReportsData,
  setDefaultSettings,
  setSaveSettings,
  setMainEmailContent,
  setFooterEmailContent,
  setIncludedSysValues,
  setIsSubjectTouched,
  setSystemValuesWithData,
  setSubject,
  setCompiledHtmlTemplate,
  resetDsrBulkShareReportsData
} = requestListSlice.actions

export default requestListSlice.reducer
