import {
  mapQueryDSRTicketsList,
  mapQueryDsrTicketsSummary,
  queryDSRTicketsList,
  queryDsrTicketsSummary,
  queryDsrTicketDetails,
  mapQueryDsrTicketDetails,
  queryMutationUpdateDsrTicket,
  mapQueryDSRTicketPurposes,
  queryDSRTicketPurposes,
  queryTicketInstancesValidatedCounts,
  mapQueryTicketInstancesValidatedCounts,
  queryAssignDsrTicket,
  assignDsrRequestMapper,
  queryDsrTicketsSummaryCounts,
  mapQueryDsrTicketsSummaryCounts,
  mutationSendReminder,
  mutationReassignTicket,
  mutationSendNewTicket,
  mutationRevokeAssignment,
  mutationRejectTicket,
  queryDSRTicketDatasources,
  mapQueryDSRTicketDatasources
} from './queries'
import {
  queryDsrAttributes,
  mapQueryDsrAttributes,
  queryDsrEntityAttributeInstances,
  mapQueryDsrEntityAttributeInstances
} from '../queries'
import graphqlService from '../../../services/graphqlService'
import { Attribute, Datasource, DsrRequestType } from '../../../services/graphqlSchemaTypes'
import apiService from '../../../services/api/apiService'
import { defaultSortParams, SortParams } from '../../../utils/sortUtil'
import { FilterParams } from '../../../interfaces'
import { NameIdSummary } from '../../ropa/ropaSlice'
import { DATA_SOURCE_ID, DSR_TICKET_STATUS_FILTER_TYPES, ENTITY_ID } from '../../../constants'
import { transformFileToDataUrl } from '../../../utils/documentUtil'
import { DsrRequest } from '../requestList/dsrRequestSlice'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export enum DsrAttributeActionTypes {
  // ACCESS
  dataFound = 'DATA_MATCH_FOUND',
  attributeUpdated = 'ATTRIBUTE_UPDATED',
  valueNotFound = 'ATTRIBUTE_VALUE_NOT_FOUND',
  dataNotExists = 'DATA_NO_LONGER_EXISTS',

  // ERSURE
  eraseAttribute = 'ERASED',
  dropAttribute = 'DROP',
  noAction = 'NO_ACTION',

  // RECFIFICATION
  rectified = 'RECTIFIED',
  notFound = 'NOT_FOUND',

  // ERSURE, RECFIFICATION
  holdAttribute = 'HOLD'
}
export type DsrTicketAttribute = {
  attributeId: string
  attributeName: string
  value: string
}
export type DsrAttributeAction = {
  attributeId: string
  attributeName: string
  attributeInstanceId: string
  action?: DsrAttributeActionTypes
  remark: string
  origin?: string
  manualValue?: string
  reason?: string[]
  previousValue?: string
  dataMismatch?: boolean
}

export type DsrTicket = {
  id?: string
  name?: string
  request?: DsrRequest
  datasource?: Datasource
  dueDate?: string
  status?: string
  assigneeEmail?: string
  isDraft?: boolean
  createdAt?: string
  completionDate?: string
  actions?: DsrAttributeAction[]
  attachments?: NameIdSummary[]
  purpose?: string[]
  datasourceId?: string
  remark?: string
  reason?: string[]
  entityId?: string
}

export type DsrEntityAttributeInstance = {
  id: string
  name: string
  values: string
}

export type DsrTicketDetailParams = {
  ticketId: string
  requestType: DsrRequestType
}
export const ACTION_DSR_TICKET_DETAILS = 'dsrTickets/ticketDetails'
export const fetchDsrTicketDetails = createAsyncThunk(
  ACTION_DSR_TICKET_DETAILS,
  async (params: DsrTicketDetailParams) => {
    const raw = await graphqlService.execute(queryDsrTicketDetails(params))
    return mapQueryDsrTicketDetails(raw)
  }
)

export type DsrEntityAttributeInstancesParams = {
  [DATA_SOURCE_ID]: string
  [ENTITY_ID]: string
  filters?: FilterParams
}
export const ACTION_DSR_ENTITY_ATTRIBUTE_INSTANCES = 'dsrTickets/entityAttributeInstances'
export const fetchDsrEntityAttributeInstances = createAsyncThunk(
  ACTION_DSR_ENTITY_ATTRIBUTE_INSTANCES,
  async (params: DsrEntityAttributeInstancesParams) => {
    const raw = await graphqlService.execute(queryDsrEntityAttributeInstances(params))
    return mapQueryDsrEntityAttributeInstances(raw)
  }
)

export const ACTION_DSR_ATTRIBUTES = 'dsrTickets/attributes'
export const fetchDsrAttributes = createAsyncThunk(ACTION_DSR_ATTRIBUTES, async () => {
  const resultRaw = await graphqlService.execute(queryDsrAttributes())
  return mapQueryDsrAttributes(resultRaw)
})

export type DsrTicketListParams = {
  filters?: FilterParams
  page: number
}

export const ACTION_DSR_TICKETS = 'dsrTickets/list'
export const fetchDsrTickets = createAsyncThunk(
  ACTION_DSR_TICKETS,
  async (params: DsrTicketListParams) => {
    const resultRaw = await graphqlService.execute(queryDSRTicketsList(params))
    return mapQueryDSRTicketsList(resultRaw)
  }
)

export const ACTION_DSR_TICKET_PURPOSES = 'dsrTickets/purposes'
export const fetchDsrTicketPurposes = createAsyncThunk(
  ACTION_DSR_TICKET_PURPOSES,
  async (requestId: string) => {
    const resultRaw = await graphqlService.execute(queryDSRTicketPurposes(requestId))
    return mapQueryDSRTicketPurposes(resultRaw)
  }
)

export const ACTION_DSR_INSTANCES_VALIDATED_COUNTS = 'dsrTickets/attrs-validated'
export const fetchTicketInstancesValidatedCounts = createAsyncThunk(
  ACTION_DSR_INSTANCES_VALIDATED_COUNTS,
  async (requestId: string) => {
    const resultRaw = await graphqlService.execute(queryTicketInstancesValidatedCounts(requestId))
    return mapQueryTicketInstancesValidatedCounts(resultRaw)
  }
)

export const ACTION_DSR_TICKETS_SUMMARY = 'dsrTickets/list/summary'
export const fetchDsrTicketsSummary = createAsyncThunk(
  ACTION_DSR_TICKETS_SUMMARY,
  async (filters: FilterParams) => {
    const resultRaw = await graphqlService.execute(queryDsrTicketsSummary(filters))
    return mapQueryDsrTicketsSummary(resultRaw)
  }
)

export const ACTION_DSR_TICKETS_SUMMARY_COUNTS = 'dsrTickets/list/summary-counts'
export const fetchDsrTicketsSummaryCounts = createAsyncThunk(
  ACTION_DSR_TICKETS_SUMMARY_COUNTS,
  async (params: DsrTicketListParams) => {
    const resultRaw = await graphqlService.execute(queryDsrTicketsSummaryCounts(params))
    return mapQueryDsrTicketsSummaryCounts(resultRaw)
  }
)

export type DsrTicketInputData = {
  dueDate: string
  isDraft: boolean
  purpose: string[]
  remark: string
  actions: Array<{
    attributeId: string
    attributeInstanceId: string
    action?: DsrAttributeActionTypes
    origin?: string
    manualValue?: string
    remark: string
    reason?: string[]
    previousValue?: string
    dataMismatch?: boolean
  }>
}
export type SaveDsrTicketParams = {
  ticketId: string
  requestId?: string
  attachmentIds?: string[]
  ticketData: DsrTicketInputData
  requestType: DsrRequestType
  isSavedByAdmin?: boolean
}
export const ACTION_DSR_TICKET_SAVE = 'dsrTickets/saveTicket'
export const saveDsrTicket = createAsyncThunk(
  ACTION_DSR_TICKET_SAVE,
  async (params: SaveDsrTicketParams, { getState }) => {
    const { attachmentIds = [], requestId = '' } = params
    const files =
      (getState() as { dsrTickets: { attachments: FileInfo[] } }).dsrTickets.attachments || []
    const ids =
      files.length > 0
        ? await apiService.postDsrAdditionalAttributes({
            requestId,
            files
          })
        : []
    await graphqlService.execute(
      queryMutationUpdateDsrTicket({ ...params, attachmentIds: [...ids, ...attachmentIds] })
    )
    return { isSavedByAdmin: params.isSavedByAdmin }
  }
)

export interface AssignTicketParams {
  requestId?: string
  datasourceIds: string[]
  text: string
  dueDate: string
  assigneeEmail?: string
  isSavedByAdmin?: boolean
  requestType: DsrRequestType
  updateData?: DsrTicketInputData
}
export const DSR_TICKET_ASSIGN = 'dsr/ticket/assign'
export const assignDsrTicket = createAsyncThunk(
  DSR_TICKET_ASSIGN,
  async (params: AssignTicketParams, { dispatch }) => {
    const raw = await graphqlService.execute(queryAssignDsrTicket(params))
    const { requestType, updateData, requestId } = params
    const ticketId = raw[assignDsrRequestMapper[requestType]]?.ticket[0] || ''
    if (ticketId && updateData) {
      await dispatch(
        saveDsrTicket({
          requestId,
          requestType,
          ticketId,
          ticketData: updateData
        })
      )
    }
    return { isSavedByAdmin: params.isSavedByAdmin || false }
  }
)

export interface UploadTicketAttributesParams {
  requestId: string
  files: FileInfo[]
}
export const ACTION_UPLOAD_ADDITIONAL_ATTRIBUTES = 'dsrTickets/upload-attributes'
export const uploadDsrTicketAdditionalAttributes = createAsyncThunk(
  ACTION_UPLOAD_ADDITIONAL_ATTRIBUTES,
  async (params: UploadTicketAttributesParams) => {
    return await apiService.postDsrAdditionalAttributes(params)
  }
)

export const ACTION_DETECT_ATTRIBUTES = 'dsr/detect-attributes'
export const detectAttributesFromFile = createAsyncThunk(
  ACTION_DETECT_ATTRIBUTES,
  async (file: File) => {
    const data = await apiService.detectAttributesFromFile(file)
    return {
      data,
      file: {
        fileContent: ((await transformFileToDataUrl(file)) as string).replace(/^.*;base64,/, ''),
        fileName: file.name,
        fileMimeType: file.type
      }
    }
  }
)

export const ACTION_FETCH_ATTACHMENT_BY_ID = 'attachment/get-by-id'
export const getAttachmentById = createAsyncThunk(
  ACTION_FETCH_ATTACHMENT_BY_ID,
  async (id: string) => {
    return await apiService.getAttachmentById(id)
  }
)

export const FETCH_DSR_TICKET_ASSIGNEE_EMAILS = 'fetch/dsr/ticket/assignee-emails'
export const fetchDSRTicketAssigneeEmails = createAsyncThunk(
  FETCH_DSR_TICKET_ASSIGNEE_EMAILS,
  async () => {
    return await apiService.getDSRTicketAssigneeEmails()
  }
)

export const FETCH_DSR_TICKET_DATASOURCES = 'fetch/dsr/ticket/datasources'
export const fetchDSRTicketDatasources = createAsyncThunk(
  FETCH_DSR_TICKET_DATASOURCES,
  async () => {
    const resultRaw = await graphqlService.execute(queryDSRTicketDatasources())
    return mapQueryDSRTicketDatasources(resultRaw)
  }
)

export interface TicketActionParams {
  ticketId: string
  requestId?: string
  dataSourceId?: string
  dueDate?: string
  assigneeEmail?: string
  emailBody?: string
}
export const ACTION_SEND_REMINDER = 'dsr/sendReminder'
export const sendReminder = createAsyncThunk(
  ACTION_SEND_REMINDER,
  async (params: TicketActionParams) => {
    await graphqlService.execute(mutationSendReminder(params))
  }
)

export const ACTION_REASSIGN_TICKET = 'dsr/reassignTicket'
export const reassignTicket = createAsyncThunk(
  ACTION_REASSIGN_TICKET,
  async (params: TicketActionParams) => {
    await graphqlService.execute(mutationReassignTicket(params))
  }
)

export const ACTION_SEND_NEW_TICKET = 'dsr/sendNewTicket'
export const sendNewTicket = createAsyncThunk(
  ACTION_SEND_NEW_TICKET,
  async (params: TicketActionParams) => {
    await graphqlService.execute(mutationSendNewTicket(params))
  }
)

export const ACTION_REVOKE_ASSIGNMENT = 'dsr/revokeAssignment'
export const revokeAssignment = createAsyncThunk(
  ACTION_REVOKE_ASSIGNMENT,
  async (params: TicketActionParams) => {
    await graphqlService.execute(mutationRevokeAssignment(params))
  }
)

export const ACTION_REJECT_TICKET = 'dsr/rejectTicket'
export const rejectTicket = createAsyncThunk(
  ACTION_REJECT_TICKET,
  async (params: TicketActionParams) => {
    await graphqlService.execute(mutationRejectTicket(params))
  }
)

export const mapDsrTicketStatusToApi = {
  [DSR_TICKET_STATUS_FILTER_TYPES.open]: 'ticketAssigned,inProgress',
  [DSR_TICKET_STATUS_FILTER_TYPES.closed]: 'rejected,completed'
}

export type TicketsListSettings = {
  list?: DsrTicket[]
  sort: SortParams
}
export interface AttributeInstancesValidatedSummary {
  datasourceId: string
  count: number
}
export interface AttributeDetectedSummary {
  attributeName: string
  attributeId: string
  value: string
}
export interface FileInfo {
  fileContent: string
  fileName: string
  fileMimeType: string
}
interface DsrTicketsSlice {
  attributes?: Attribute[]
  entityAttributeInstances: {
    list: DsrEntityAttributeInstance[]
    total: number
  }
  refreshDatasources: boolean
  refreshTicketDetails: boolean
  ticketDetails?: DsrTicket
  attachments?: FileInfo[]
  isTicketSavedByAdmin: boolean
  tickets: TicketsListSettings
  totalTickets?: number
  totalTicketsInProgress?: number
  totalTicketsCompleted?: number
  detectedAttributes?: AttributeDetectedSummary[]
  purposes?: string[]
  attributeInstancesValidated?: AttributeInstancesValidatedSummary[]
  assigneeEmail?: Array<string>
  isTicketReminderSent?: boolean
  isTicketReassigned?: boolean
  isNewTicketSent?: boolean
  isTicketRevoked?: boolean
  isTicketRejected?: boolean
  datasources?: NameIdSummary[]
}
const initialList: TicketsListSettings = {
  sort: defaultSortParams
}
export const initialState: DsrTicketsSlice = {
  tickets: initialList,
  isTicketSavedByAdmin: false,
  refreshDatasources: false,
  refreshTicketDetails: false,
  entityAttributeInstances: { list: [], total: 0 }
}

const dsrTicketsSlice = createSlice({
  name: 'dsrTickets',
  initialState,
  reducers: {
    updateTicketDetails: (state, { payload }) => {
      state.ticketDetails = payload
    },
    resetRefreshDatasources: (state) => {
      state.refreshDatasources = initialState.refreshDatasources
    },
    resetRefreshTicketDetails: (state) => {
      state.refreshTicketDetails = initialState.refreshTicketDetails
    },
    resetTicketDetails: (state) => {
      state.ticketDetails = initialState.ticketDetails
    },
    resetDsrTickets: (state) => {
      state.tickets.list = initialState.tickets.list
    },
    resetDetectedAttributes: (state) => {
      state.detectedAttributes = initialState.detectedAttributes
    },
    resetIsTicketSavedByAdmin: (state) => {
      state.isTicketSavedByAdmin = initialState.isTicketSavedByAdmin
    },
    resetDsrEntityAttributeInstances: (state) => {
      state.entityAttributeInstances = initialState.entityAttributeInstances
    },
    resetDsrTicketsSummaryCounts: (state) => {
      state.totalTickets = initialState.totalTickets
      state.totalTicketsCompleted = initialState.totalTicketsCompleted
      state.totalTicketsInProgress = initialState.totalTicketsInProgress
    },
    resetIsTicketReminderSent: (state) => {
      state.isTicketReminderSent = initialState.isTicketReminderSent
    },
    resetIsTicketReassigned: (state) => {
      state.isTicketReassigned = initialState.isTicketReassigned
    },
    resetIsNewTicketSent: (state) => {
      state.isNewTicketSent = initialState.isNewTicketSent
    },
    resetIsTicketRevoked: (state) => {
      state.isTicketRevoked = initialState.isTicketRevoked
    },
    resetIsTicketRejected: (state) => {
      state.isTicketRejected = initialState.isTicketRejected
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDsrTicketDetails.fulfilled, (state, { payload }) => {
      state.ticketDetails = payload
    })
    builder.addCase(fetchDsrEntityAttributeInstances.fulfilled, (state, { payload }) => {
      state.entityAttributeInstances = payload
    })
    builder.addCase(fetchDsrTickets.fulfilled, (state, { payload }) => {
      state.tickets.list = payload
    })
    builder.addCase(fetchDsrTicketsSummary.fulfilled, (state, { payload }) => {
      state.tickets.list = payload
    })
    builder.addCase(fetchDsrAttributes.fulfilled, (state, { payload }) => {
      state.attributes = payload
    })
    builder.addCase(fetchDsrTicketPurposes.fulfilled, (state, { payload }) => {
      state.purposes = payload
    })
    builder.addCase(fetchTicketInstancesValidatedCounts.fulfilled, (state, { payload }) => {
      state.attributeInstancesValidated = payload
    })
    builder.addCase(detectAttributesFromFile.fulfilled, (state, { payload }) => {
      state.detectedAttributes = payload.data
      if (state.attachments) {
        state.attachments.push(payload.file)
      } else {
        state.attachments = [payload.file]
      }
    })
    builder.addCase(assignDsrTicket.fulfilled, (state, { payload }) => {
      state.attachments = initialState.attachments
      if (payload.isSavedByAdmin) {
        state.isTicketSavedByAdmin = payload.isSavedByAdmin || false
      } else {
        state.refreshDatasources = true
      }
    })
    builder.addCase(saveDsrTicket.fulfilled, (state, { payload }) => {
      state.attachments = initialState.attachments
      state.isTicketSavedByAdmin = payload.isSavedByAdmin || false
    })
    builder.addCase(fetchDsrTicketsSummaryCounts.fulfilled, (state, { payload }) => {
      state.totalTickets = payload.totalTickets
      state.totalTicketsCompleted = payload.totalTicketsCompleted
      state.totalTicketsInProgress = payload.totalTicketsInProgress
    })
    builder.addCase(fetchDSRTicketAssigneeEmails.fulfilled, (state, { payload }) => {
      state.assigneeEmail = payload.assigneeEmail
    })

    builder.addCase(sendReminder.fulfilled, (state) => {
      state.isTicketReminderSent = true
      state.refreshDatasources = true
    })
    builder.addCase(sendReminder.rejected, (state) => {
      state.isTicketReminderSent = false
    })

    builder.addCase(reassignTicket.fulfilled, (state) => {
      state.isTicketReassigned = true
      state.refreshDatasources = true
      state.refreshTicketDetails = true
    })
    builder.addCase(reassignTicket.rejected, (state) => {
      state.isTicketReassigned = false
    })

    builder.addCase(sendNewTicket.fulfilled, (state) => {
      state.isNewTicketSent = true
      state.refreshDatasources = true
    })
    builder.addCase(sendNewTicket.rejected, (state) => {
      state.isNewTicketSent = false
    })

    builder.addCase(revokeAssignment.fulfilled, (state) => {
      state.isTicketRevoked = true
      state.refreshDatasources = true
    })
    builder.addCase(revokeAssignment.rejected, (state) => {
      state.isTicketRevoked = false
    })

    builder.addCase(rejectTicket.fulfilled, (state) => {
      state.isTicketRejected = true
      state.refreshTicketDetails = true
    })
    builder.addCase(rejectTicket.rejected, (state) => {
      state.isTicketRejected = false
    })
    builder.addCase(fetchDSRTicketDatasources.fulfilled, (state, { payload }) => {
      state.datasources = payload
    })
  }
})

export const {
  updateTicketDetails,
  resetDsrTickets,
  resetIsTicketSavedByAdmin,
  resetRefreshDatasources,
  resetRefreshTicketDetails,
  resetDsrEntityAttributeInstances,
  resetTicketDetails,
  resetDetectedAttributes,
  resetDsrTicketsSummaryCounts,
  resetIsTicketReminderSent,
  resetIsTicketReassigned,
  resetIsNewTicketSent,
  resetIsTicketRevoked,
  resetIsTicketRejected
} = dsrTicketsSlice.actions

export default dsrTicketsSlice.reducer
