import {
  AttributesValidated,
  AttributeValidated,
  DsrBulkShareReportParams,
  DsrReassignRequestParams,
  DsrReportPrint,
  DsrReportValidatedAttribute,
  DSRRequest,
  DsrRequest,
  DsrRequestActionParams,
  DsrRequestFilterParams,
  FetchAttributesValidatedParams,
  IGetRequestListParams,
  IncludeAttributeInReportParams,
  mapDsrStatusToApi,
  ReportAttributesSummaryParams
} from './dsrRequestSlice'
import {
  FILTER_DSR_REQUEST_ASSIGNEE,
  FILTER_DSR_REQUEST_TEMPLATE,
  LIMIT_DEFAULT
} from '../../../constants'
import { FilterOptions } from '../requestDetails/components/attributeList/attributeListSlice'
import { DsrAttributeActionTypes } from '../tickets/dsrTicketsSlice'
import { DsrRequestStatus, DsrRequestType } from '../../../services/graphqlSchemaTypes'
import {
  getAfterCursor,
  parameterizeArrayofObjects,
  stringifyParams
} from '../../../utils/graphqlUtil'
import { DsrRequestApiKeys } from '../dsrForms/dsrFormsSlice'

import { DsrEmailTemplateTypes } from '../dsrEmailTemplates/dsrEmailTemplatesSlice'
import { NameIdSummary } from '../../pia/piaSlice'
import { gql } from 'graphql-request'

export const queryDSRRequestList = (params: IGetRequestListParams): string => {
  const { page, ...listFilters } = params
  const cursor = getAfterCursor(page, LIMIT_DEFAULT)
  const filterString = parameterizeArrayofObjects(listFilters.filters?.filter || [])

  const dateFilterString = listFilters.filters?.dateFilter?.map((dateFilter) => {
    return `{${stringifyParams(dateFilter)}},`
  })

  const searchQuery = listFilters.filters?.searchQuery || ''
  let commonParamString = ''
  if (filterString) {
    commonParamString += `,filter:${filterString}`
  }
  if (dateFilterString) {
    commonParamString += `,dateFilter:[${dateFilterString}]`
  }
  if (searchQuery) {
    commonParamString += `,dataSubjectNameOrEmail:"${searchQuery}"`
  }

  return gql`
  {
    dsrRequest(sortField: REQUEST_RECEIVED_AT, first: ${LIMIT_DEFAULT}, after: "${cursor}", ${commonParamString}) {
      edges {
        node {
          id
          name
          assignee {
            email
          }
          dataSubjectMandatoryDetails {
            firstName
            lastName
            email
          }
          detectedEntity {
            edges{
              node{
                id
              }
            }
          }
          tickets {
            count
          }
          openedTicketsCount: tickets(filter: {key: STATUS, values: ["ticketAssigned", "inProgress"]}) {
            count
          }
          requestType
          status
          createdAt
          dueDate
          form {
            edges {
              node {
                id
                name
              }
            }
          }
          manualAction
          automationFailureOccurred
          workflowConfig {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`
}

export const mapQueryRequestList = (raw: any): DSRRequest[] => {
  const list = raw.dsrRequest.edges.map(({ node: request }) => {
    return {
      ...request,
      hasOpenTickets: request.openedTicketsCount.count > 0,
      entityId: request.detectedEntity?.edges[0]?.node?.id,
      formId: request.form?.edges[0]?.node?.id || '',
      formName: request.form?.edges[0]?.node?.name || '',
      workflowConfig: request.workflowConfig?.edges[0]?.node || {}
    }
  })

  return list
}

export const queryDSRRequestIdsForBulkActions = (
  params: DsrRequestFilterParams & { searchQuery?: string }
): string => {
  const filterString = parameterizeArrayofObjects(params?.filter || [])

  const dateFilterString = params?.dateFilter?.map((dateFilter) => {
    return `{${stringifyParams(dateFilter)}},`
  })

  const searchQuery = params?.searchQuery || ''
  let commonParamString = ''
  if (filterString) {
    commonParamString += `,filter:${filterString}`
  }
  if (dateFilterString) {
    commonParamString += `,dateFilter:[${dateFilterString}]`
  }
  if (searchQuery) {
    commonParamString += `,dataSubjectNameOrEmail:"${searchQuery}"`
  }

  return gql`
  {
    dsrRequest(sortField: REQUEST_RECEIVED_AT, ${commonParamString}) {
      edges {
        node {
          id
        }
      }
    }
  }
`
}

export const mapQueryDsrRequestIdsForBulkAction = (result: any): string[] => {
  return result.dsrRequest.edges.map(({ node }: any) => node.id)
}

export const queryDSRRequestsListForBulkAction = (requestIds: string[]): string => {
  return gql`
  {
    dsrRequest(sortField: REQUEST_RECEIVED_AT,filter: {key: DSR_IDS, values: ${JSON.stringify(
      requestIds
    )}}) {
      edges {
        node {
          id
          name
          assignee {
            email
          }
          dataSubjectMandatoryDetails {
            firstName
            lastName
            email
          }
          detectedEntity {
            edges{
              node{
                id
              }
            }
          }
          tickets {
            count
          }
          openedTicketsCount: tickets(filter: {key: STATUS, values: ["ticketAssigned", "inProgress"]}) {
            count
          }
          requestType
          status
          createdAt
          dueDate
          form {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`
}

export const queryAllDsrRequestsRequiredDetails = () => {
  return gql`
    {
      allDsrRequestDetailsRequired: dsrRequest(sortField: REQUEST_RECEIVED_AT) {
        edges {
          node {
            id
            openedTicketsCount: tickets(
              filter: { key: STATUS, values: ["ticketAssigned", "inProgress"] }
            ) {
              count
            }
            status
          }
        }
      }
    }
  `
}

export const mapQueryAllDsrRequestsRequiredDetails = (
  raw: any
): { id: string; hasOpenTickets: boolean; status: DsrRequestStatus }[] => {
  const list = raw.allDsrRequestDetailsRequired.edges.map(({ node: request }) => {
    return {
      ...request,
      hasOpenTickets: request.openedTicketsCount.count > 0
    }
  })

  return list
}

export const queryDSRRequestsSummaryByStatus = (params: IGetRequestListParams): string => {
  const { ...listFilters } = params

  const filterString = parameterizeArrayofObjects(listFilters.filters?.filter || [])

  const dateFilterString = listFilters.filters?.dateFilter?.map((dateFilter) => {
    return `{${stringifyParams(dateFilter)}},`
  })

  const searchQuery = listFilters.filters?.searchQuery || ''
  let commonParamString = ''

  if (dateFilterString) {
    commonParamString += `,dateFilter:[${dateFilterString}]`
  }
  if (searchQuery) {
    commonParamString += `,dataSubjectNameOrEmail:"${searchQuery}"`
  }

  const filters = listFilters.filters?.filter || []

  const openFilter = parameterizeArrayofObjects([
    { key: 'STATUS', values: mapDsrStatusToApi.inProgress.split(',') },
    ...(filters || [])
  ])
  const openFilterString = openFilter ? `(filter: ${openFilter}${commonParamString})` : ''

  const closedFilter = parameterizeArrayofObjects([
    { key: 'STATUS', values: mapDsrStatusToApi.closed.split(',') },
    ...(filters || [])
  ])
  const closedFilterString = closedFilter ? `(filter: ${closedFilter}${commonParamString})` : ''

  const unverifiedFilter = parameterizeArrayofObjects([
    { key: 'STATUS', values: mapDsrStatusToApi.unverified.split(',') },
    ...(filters || [])
  ])
  const unverifiedFilterString = unverifiedFilter
    ? `(filter: ${unverifiedFilter}${commonParamString})`
    : ''
  const allFilterString = `(filter: ${
    filterString !== '' ? filterString : '[]'
  }${commonParamString})`

  return gql`
  {
    totalRequestsInProgress: dsrRequest${openFilterString} {
      count
    }
    totalRequestsClosed: dsrRequest${closedFilterString} {
      count
    }
    totalRequestsInUnverified: dsrRequest${unverifiedFilterString} {
      count
    }
    totalRequests: dsrRequest${allFilterString} {
      count
    }
  }
`
}

export const mapQueryDSRRequestsSummaryByStatus = (
  raw: any
): {
  totalRequestsInProgress: number
  totalRequestsClosed: number
  totalRequestsInUnverified: number
  totalRequests: number
} => {
  return {
    totalRequestsInProgress: raw.totalRequestsInProgress.count || 0,
    totalRequestsClosed: raw.totalRequestsClosed.count || 0,
    totalRequestsInUnverified: raw.totalRequestsInUnverified.count || 0,
    totalRequests: raw.totalRequests.count || 0
  }
}

export const queryDSRRequestById = (id: string): string => {
  return gql`
  {
    dsrRequest(id: "${id}") {
      edges {
        node {
          id
          name
          status
          createdAt
          dueDate
          requestType
          form {
            edges {
              node {
                id
                name
                formRawJSON
              }
            }
          }
          submittedValues
          attachments {
            edges {
              node {
                id
                fileName
              }
            }
          }
          detectedEntity {
            edges {
              node {
                id
              }
            }
          }
          emailVerificationSentDate
          emailVerificationCompletionDate
          approvedBy
          approvedAt
          assignee {
            email
            name
          }
          dataSubjectMandatoryDetails {
            firstName
            lastName
            email
          }
          dataSubjectDetails {
            attributeName
            attributeValue
          }
          erasureData
          rectificationData {
            attribute {
              edges {
                node {
                  id
                  name
                }
              }
            }
            oldValue
            newValue
          }
          manualAction
          automationFailureOccurred
          workflowConfig {
            edges {
              node {
                id
                name
                requestType
              }
            }
          }
        }
      }
    }
  }
`
}

export const mapQueryDSRRequestById = (raw: any) => {
  const request = raw.dsrRequest?.edges[0]?.node || {}

  const requestDetails: DsrRequest = {
    id: request.id || '',
    name: request.name || '',
    status: request.status || '',
    dueDate: request.dueDate || '',
    createdAt: request.createdAt || '',
    requestType: request.requestType || '',
    detectedEntityId: request.detectedEntity?.edges[0]?.node?.id || '',
    formId: request.form?.edges[0]?.node?.id || '',
    formName: request.form?.edges[0]?.node?.name || '',
    formRawJSON: request.form?.edges[0]?.node.formRawJSON || '',
    formJsonParsed: request.form?.edges[0]?.node.formRawJSON
      ? JSON.parse(request.form?.edges[0]?.node.formRawJSON)
      : {},
    submittedValues: request.submittedValues || '',
    attachments: request.attachments?.edges?.map(({ node }) => node) || [],
    emailVerificationSentDate: request.emailVerificationSentDate || '',
    emailVerificationCompletionDate: request.emailVerificationCompletionDate || '',
    approvedBy: request.approvedBy || '',
    approvedAt: request.approvedAt || '',
    assignee: request.assignee || '',
    dataSubjectMandatoryDetails: request.dataSubjectMandatoryDetails,
    dataSubjectDetails: request.dataSubjectDetails || {},
    rectificationAttributeIds:
      request.rectificationData?.map(({ attribute }) => attribute.edges[0]?.node?.id || '') || [],
    rectificationData:
      request.rectificationData?.map(({ attribute, oldValue, newValue }) => ({
        newValue,
        oldValue,
        attributeId: attribute.edges[0]?.node?.id || '',
        attributeName: attribute.edges[0]?.node?.name || ''
      })) || [],
    erasureData: request.erasureData || '',
    manualAction: request.manualAction || '',
    automationFailureOccurred: request.automationFailureOccurred || '',
    workflowConfig: request.workflowConfig?.edges[0]?.node || {}
  }

  return requestDetails
}

export const queryFilters = (): string => {
  return gql`
    {
      dsrListFilters {
        assignee {
          edges {
            node {
              assignee
            }
          }
        }
        template {
          edges {
            node {
              template
            }
          }
        }
      }
    }
  `
}

export const mapQueryFilters = (raw: any): FilterOptions => {
  return {
    [FILTER_DSR_REQUEST_ASSIGNEE]: raw.dsrListFilters[FILTER_DSR_REQUEST_ASSIGNEE].edges.map(
      ({ node }) => node[FILTER_DSR_REQUEST_ASSIGNEE]
    ),
    [FILTER_DSR_REQUEST_TEMPLATE]: raw.dsrListFilters[FILTER_DSR_REQUEST_TEMPLATE].edges.map(
      ({ node }) => node[FILTER_DSR_REQUEST_TEMPLATE]
    )
  }
}

export const queryRequestsSummaryByType = (): string => {
  return gql`
    {
      totalRequestsToAccess: dsrRequest(filter: [{ key: REQUEST_TYPE, values: ["${DsrRequestApiKeys.access}"] }]) {
        count
      }
      totalRequestsToErase: dsrRequest(filter: [{ key: REQUEST_TYPE, values: ["${DsrRequestApiKeys.erasure}"] }]) {
        count
      }
      totalRequestsToNotSell: dsrRequest(
        filter: [{ key: REQUEST_TYPE, values: ["${DsrRequestApiKeys.doNotSell}"] }]
      ) {
        count
      }
      totalRequestsOther: dsrRequest(filter: [{ key: REQUEST_TYPE, values: ["${DsrRequestApiKeys.rectification}", "${DsrRequestApiKeys.other}"] }]) {
        count
      }
    }
  `
}

export const mapQueryRequestsSummaryByType = (
  raw: any
): {
  totalRequestsToAccess?: number
  totalRequestsToErase?: number
  totalRequestsToNotSell?: number
  totalRequestsOther?: number
} => {
  try {
    return {
      totalRequestsToAccess: raw.totalRequestsToAccess?.count || 0,
      totalRequestsToErase: raw.totalRequestsToErase?.count || 0,
      totalRequestsToNotSell: raw.totalRequestsToNotSell?.count || 0,
      totalRequestsOther: raw.totalRequestsOther?.count || 0
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryTotalRequests = (): string => {
  return gql`
    {
      dsrRequest {
        count
      }
    }
  `
}

export const mapQueryTotalRequests = (raw: any): number => {
  return raw.dsrRequest.count || 0
}

export const queryCloseDsrRequest = ({
  requestIds,
  content = '',
  subject = '',
  systemValues = []
}: DsrRequestActionParams) => {
  return gql`
    mutation {
      closeDsrRequest(
      clientMutationId: "queryCloseDsrRequest",
      requestIds: ${JSON.stringify(requestIds)},
      emailConfiguration:{
        notifyRequester: ${!!content}
        emailSubjectLine: "${subject}",
        compiledHTML:"${content}",
        includedSystemValues: [${systemValues.map((val) => val)}]
      }) {
        clientMutationId
      }
    }
    `
}

export const queryVerifyDsrRequest = ({
  requestIds,
  content = '',
  subject = '',
  systemValues = []
}: DsrRequestActionParams) => {
  return gql`
    mutation {
      verifyDsrRequest(
      clientMutationId: "approveRequest",
      requestIds: ${JSON.stringify(requestIds)},
      emailConfiguration:{
        notifyRequester: ${!!content}
        emailSubjectLine: "${subject}",
        compiledHTML:"${content}",
        includedSystemValues: [${systemValues.map((val) => val)}]
      }) {
        clientMutationId
      }
    }
    `
}
export const queryRejectDsrRequest = ({
  requestIds,
  content = '',
  subject = '',
  systemValues = []
}: DsrRequestActionParams) => {
  return gql`
    mutation {
      rejectDsrRequest(
      clientMutationId: "rejectDsrRequest",
      requestIds: ${JSON.stringify(requestIds)},
      emailConfiguration:{
        notifyRequester: ${!!content}
        emailSubjectLine: "${subject}",
        compiledHTML:"${content}",
        includedSystemValues: [${systemValues.map((val) => val)}]
      }) {
        clientMutationId
      }
    }
  `
}
export const queryExtendDsrRequest = ({
  requestIds,
  dueDate = '',
  content = '',
  subject = '',
  systemValues = []
}: DsrRequestActionParams) => {
  return gql`
    mutation {
      extendDsrRequest(
      clientMutationId: "extendDsrRequest",
      requestId: "${requestIds[0]}",
      newDueDate: "${dueDate}",
      emailConfiguration:{
        notifyRequester: ${!!content}
        emailSubjectLine: "${subject}",
        compiledHTML:"${content}",
        includedSystemValues: [${systemValues.map((val) => val)}]
      }) {
        clientMutationId
      }
    }
  `
}
export const queryReassignDsrRequest = ({
  requestIds,
  assigneeEmailId = '',
  assigneeName = '',
  assignorEmailId = '',
  emailBody = ''
}: DsrReassignRequestParams) => {
  return gql`
    mutation {
      reassignDsrRequest(
        clientMutationId: "reassignDsrRequest"
        requestIds: ${JSON.stringify(requestIds)},
        assigneeEmailId: "${assigneeEmailId}"
        assigneeName: "${assigneeName}"
        assignorEmailId: "${assignorEmailId}"
        emailBody: "${encodeURIComponent(emailBody)}"
      ) {
        clientMutationId
      }
    }
  `
}

export const queryExcludeAttributeForDsrReport = ({
  dsrReportId,
  attributeIds
}: IncludeAttributeInReportParams) => {
  const attributeIdsArr = attributeIds.map((id) => '"' + id + '"')

  return gql`
    mutation {
      removeAttributesFromDsrReport(clientMutationId: "removeAttributesFromDsrReport", dsrReportId: "${dsrReportId}", attributeIds:[${attributeIdsArr.join(
    ','
  )}]) {
        clientMutationId
      }
    }
  `
}

export const queryDSRAttributesValidated = ({
  requestId
}: FetchAttributesValidatedParams): string => {
  return gql`
    {
      dsrReport(filter: [{key: REQUEST_ID,values: ["${requestId}"]}]) {
        edges {
          node {
            id
            validatedAttributes {
              attributeId
              attributeTypeName
              validatedAttributeValues {
                action
                reason
                remark
                attributeValidatedId
                attributeValidatedValue
                includeInReport
                dsrTicket {
                  edges {
                    node {
                      datasource {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryDSRAttributesValidated = (
  raw: any,
  requestType: DsrRequestType
): AttributesValidated => {
  const isRectification = requestType == DsrRequestType.Rectification
  const isErasure = requestType == DsrRequestType.Erasure
  const list: AttributeValidated[] = []
  const report = raw.dsrReport?.edges[0].node || {}
  report.validatedAttributes?.forEach((validatedAttribute) => {
    validatedAttribute.validatedAttributeValues.forEach((validatedValue) => {
      const isActionValid = isRectification
        ? [DsrAttributeActionTypes.rectified, DsrAttributeActionTypes.holdAttribute].find(
            (act) => act == validatedValue.action
          )
        : isErasure
        ? [
            DsrAttributeActionTypes.eraseAttribute,
            DsrAttributeActionTypes.holdAttribute,
            DsrAttributeActionTypes.noAction
          ].find((act) => act == validatedValue.action)
        : true
      isActionValid &&
        list.push({
          id: validatedValue.attributeValidatedId,
          attributeId: validatedAttribute.attributeId,
          name: validatedAttribute.attributeTypeName,
          identifier: validatedValue.attributeValidatedValue,
          action: validatedValue.action,
          reason: validatedValue.reason || '',
          remark: validatedValue.remark || '',
          includeInReport: validatedValue.includeInReport || false,
          dataSources:
            validatedValue.dsrTicket?.edges[0]?.node?.datasource?.edges?.map(({ node }) => ({
              id: node.id,
              name: node.name
            })) || []
        })
    })
  })
  return { list, reportId: report.id || '' }
}

export const queryReportAttributesSummary = ({
  entityId,
  requestId,
  filters
}: ReportAttributesSummaryParams): string => {
  const filterString = parameterizeArrayofObjects(filters?.filter || [])
  let filterParamString = ''
  if (filterString) {
    filterParamString += `,filter:${filterString}`
  }
  return entityId
    ? gql`
    {
      attributeInstance(entityId: "${entityId}"${filterParamString}) {
        count
      }
      dsrReport(filter: [{key: REQUEST_ID,values: ["${requestId}"]}]) {
        edges {
          node {
            validatedAttributes {
              validatedAttributeValues {
                attributeValidatedValue
                action
              }
            }
          }
        }
      }
    }
  `
    : gql`
  {
    dsrReport(filter: [{key: REQUEST_ID,values: ["${requestId}"]}]) {
      edges {
        node {
          validatedAttributes {
            validatedAttributeValues {
              attributeValidatedValue
              action
            }
          }
        }
      }
    }
  }
`
}

export const mapQueryReportAttributesSummary = (
  raw: any
): {
  totalAttributesValidated: number
  totalAttributesDetected: number
  totalAttributesRectified: number
  totalAttributesErased: number
} => {
  let totalAttributesValidated = 0
  raw.dsrReport.edges.map(({ node: report }) => ({
    name: report.validatedAttributes.map((validatedAttribute) => {
      totalAttributesValidated += validatedAttribute.validatedAttributeValues?.length || 0
    })
  }))
  let totalAttributesRectified = 0
  raw.dsrReport.edges.map(({ node: report }) => ({
    name: report.validatedAttributes.map((validatedAttribute) => {
      totalAttributesRectified +=
        validatedAttribute.validatedAttributeValues?.filter((validatedAttributeValue) =>
          [DsrAttributeActionTypes.rectified, DsrAttributeActionTypes.holdAttribute].find(
            (actionType) => actionType == validatedAttributeValue.action
          )
        ).length || 0
    })
  }))
  let totalAttributesErased = 0
  raw.dsrReport.edges?.map(({ node: report }) => ({
    name: report.validatedAttributes.map((validatedAttribute) => {
      totalAttributesErased +=
        validatedAttribute.validatedAttributeValues?.filter((validatedAttributeValue) =>
          [
            DsrAttributeActionTypes.eraseAttribute,
            DsrAttributeActionTypes.noAction,
            DsrAttributeActionTypes.holdAttribute
          ].find((actionType) => actionType == validatedAttributeValue.action)
        ).length || 0
    })
  }))

  return {
    totalAttributesDetected: raw?.attributeInstance?.count || 0,
    totalAttributesValidated,
    totalAttributesRectified,
    totalAttributesErased
  }
}

export const queryDSRReportById = (requestId: string): string => {
  return gql`
  {
    dsrReport(filter: [{key: REQUEST_ID, values: ["${requestId}"]}]) {
      edges {
        node {
          request {
            edges {
              node {
                name
                requestType
                dataSubjectDetails {
                  attributeName
                  attributeValue
                }
                form {
                  edges {
                    node {
                      id
                      name
                      formRawJSON
                    }
                  }
                }
              }
            }
          }
          validatedAttributes {
            attributeTypeName
            validatedAttributeValues {
              includeInReport
              attributeValidatedValue
              action
              reason
              remark
            }
          }
        }
      }
    }
  }
`
}

export const mapQueryDSRReportById = (raw: any): DsrReportPrint => {
  const report = raw.dsrReport?.edges[0]?.node || {}
  const request = report.request.edges[0]?.node || {}

  const attributes: DsrReportValidatedAttribute[] = []
  report.validatedAttributes?.forEach((validatedAttribute) => {
    validatedAttribute.validatedAttributeValues.forEach((validatedAttributeValue) => {
      attributes.push({
        name: validatedAttribute.attributeTypeName,
        includeInReport: validatedAttributeValue.includeInReport || false,
        action: validatedAttributeValue.action || '',
        remarks: validatedAttributeValue.remark || '',
        reason: validatedAttributeValue.reason || '',
        value: validatedAttributeValue.attributeValidatedValue || ''
      })
    })
  })

  return {
    requestType: request.requestType,
    requestName: request.name || '',
    formJsonParsed: request.form?.edges[0]?.node.formRawJSON
      ? JSON.parse(request.form?.edges[0]?.node.formRawJSON)
      : {},
    dataSubjectDetails: request.dataSubjectDetails,
    attributes
  }
}

export const queryDsrReportByIds = (requestIds: string[]): string => {
  return gql`
    {
      dsrReport(filter: { key: REQUEST_ID, values: ${JSON.stringify(requestIds)} }) {
        edges {
          node {
            id
            request {
              edges {
                node {
                  requestType
                  id
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryDSRReportByIds = (raw: any): string[] => {
  const list: string[] = []
  raw.dsrReport.edges.forEach(({ node: report }) => {
    list.push(report.request.edges[0].node.id)
  })
  if (list.length === 0) {
    return []
  }

  return list
}

export const queryDsrRequestByIds = (ids: string[]): string => {
  return gql`
    {
      dsrRequest(filter: [{ key: DSR_IDS, values: ${JSON.stringify(ids)} }]) {
        edges {
          node {
            id
            name

          }
        }
      }
    }
  `
}

const mapFilterKeys = (
  input: DsrRequestFilterParams & { searchQuery?: string }
): DsrRequestFilterParams & { searchQuery?: string } => {
  return {
    ...input,
    filter:
      input?.filter &&
      input?.filter.map((filterItem) => {
        if (filterItem.key === 'ASSIGNEE_EMAIL') {
          return { ...filterItem, key: 'ASSIGNEE' }
        }
        return filterItem
      }),
    dateFilter:
      input?.dateFilter &&
      input?.dateFilter.map((dateItem) => {
        if (dateItem.key === 'CREATED_AT') {
          return { ...dateItem, key: 'REQUEST_CREATED_AT' }
        } else if (dateItem.key === 'DUE_DATE') {
          return { ...dateItem, key: 'REQUEST_DUE_DATE' }
        }
        return dateItem
      })
  }
}

export const mutationBulkShareReports = (params: DsrBulkShareReportParams) => {
  const {
    loggendInUserEmail,
    requestIds,
    timezone,
    closeRequests,
    sendCloseEmail,
    dsrReportShareData,
    bulkShareFilters,
    bulkShareActionType
  } = params

  const bulkShareFiltersMapped = mapFilterKeys(bulkShareFilters)

  const filterString = parameterizeArrayofObjects(bulkShareFiltersMapped?.filter || [])

  const dateFilterString = bulkShareFiltersMapped?.dateFilter?.map((dateFilter) => {
    return `{${stringifyParams(dateFilter)}},`
  })

  const searchQuery = bulkShareFiltersMapped?.searchQuery || ''
  let commonParamString = '{'
  if (filterString) {
    commonParamString += `,filter:${filterString}`
  }
  if (dateFilterString) {
    commonParamString += `,dateFilter:[${dateFilterString}]`
  }
  if (searchQuery) {
    commonParamString += `,dataSubjectNameOrEmail:"${searchQuery}"`
  }
  commonParamString += '}'

  return gql`
    mutation {
      createDsrBulkReportAction(
        clientMutationId: "kjhgv"
        input: {
          createdBy: ${JSON.stringify(loggendInUserEmail)}
          timezone: ${JSON.stringify(timezone)}
          tableFilters: ${commonParamString}
          dsrRequestIds: ${JSON.stringify(requestIds)}
          actionType: ${bulkShareActionType}
          unbranded: false
          closeRequests: ${closeRequests}
          sendCloseEmail: ${sendCloseEmail}
          ${
            sendCloseEmail
              ? `requestCloseEmailConfiguration: {
            notifyRequester: true
            emailSubjectLine: ${JSON.stringify(
              dsrReportShareData?.[DsrEmailTemplateTypes.requestClosed]?.subject
            )}
            compiledHTML: ${JSON.stringify(
              dsrReportShareData?.[DsrEmailTemplateTypes.requestClosed]?.compiledHTMLTemplate
            )}
            includedSystemValues: [${
              dsrReportShareData?.[DsrEmailTemplateTypes.requestClosed]?.includedSysValues
            }]
          }`
              : ``
          }
          reportShareEmailConfiguration: {
            notifyRequester: true
            emailSubjectLine: ${JSON.stringify(
              dsrReportShareData?.[DsrEmailTemplateTypes.reportShared]?.subject
            )}
            compiledHTML: ${JSON.stringify(
              dsrReportShareData?.[DsrEmailTemplateTypes.reportShared]?.compiledHTMLTemplate
            )}
            includedSystemValues: [${
              dsrReportShareData?.[DsrEmailTemplateTypes.reportShared]?.includedSysValues
            }]
          }
          reportPasswordEmailConfiguration: {
            notifyRequester: true
            emailSubjectLine: ${JSON.stringify(
              dsrReportShareData?.[DsrEmailTemplateTypes.reportPassword]?.subject
            )}
            compiledHTML: ${JSON.stringify(
              dsrReportShareData?.[DsrEmailTemplateTypes.reportPassword]?.compiledHTMLTemplate
            )}
            includedSystemValues: [${
              dsrReportShareData?.[DsrEmailTemplateTypes.reportPassword]?.includedSysValues
            }]
          }
        }
      ) {
        clientMutationId
      }
    }
  `
}

export const queryDSRAssociatedForms = (): string => {
  return gql`
    {
      dataSubjectRequestAssociatedForm {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `
}

export const mapQueryDSRAssociatedForms = (raw: any): NameIdSummary[] => {
  return raw?.dataSubjectRequestAssociatedForm?.edges?.map(({ node }) => node) || []
}
